<template>
    <div class="fee-information">
        <StackRouterHeaderBar
            :class="{ 'bottom-border': showHeaderTitle }"
            :title="$translate('FEE_INFORMATION')"
            :show-title="true"
            :leftButtonHandler="back"
        />
        <div class="main">
            <div class="content-section">
                <div class="first-section p-12 f-14 f-medium">
                    {{ firstSectionContent }}
                </div>
                <div class="content-titles m-t-28" v-for="item in contentTitles" :key="item.id">
                    <div class="title-container flex-row">
                        <div class="name f-medium f-17 m-b-13">
                            {{ item.title }}
                            <span class="subtitle f-regular f-13">{{
                                item.subtitle ? '(' + item.subtitle + ')' : null
                            }}</span>
                        </div>
                    </div>
                    <!-- <div v-if="item.id === 1 && descriptionFirst" class="description-box"> -->
                    <!-- </div> -->
                    <div class="content-box" v-if="item.id === 1">
                        <div class="contents contents-first" v-for="content in item.contents" :key="content.id">
                            <!-- <div v-if="(content.id === 1 && descriptionFirst) || (content.id === 2 && descriptionSecond)" class="description-box"> -->
                            <!-- <img v-if="(content.id === 1 && descriptionFirst) || (content.id === 2 && descriptionSecond)" class="description" :src="content.description" alt=""> -->
                            <!-- </div> -->
                            <img
                                v-if="content.id === 1 && descriptionFirst"
                                class="cancel-description"
                                :src="require('@/assets/images/cancel-description-1.png')"
                                alt=""
                            />
                            <img
                                v-if="content.id === 2 && descriptionSecond"
                                class="cancel-description-2"
                                :src="require('@/assets/images/cancel-description-2.png')"
                                alt=""
                            />
                            <div class="content-container">
                                <div class="left">
                                    <img :src="content.icon" alt="" width="16px" />
                                    <span class="f-15 m-l-8 m-r-8" v-html="content.title" />
                                    <img
                                        :src="require('@/assets/images/more-info.png')"
                                        alt=""
                                        width="14px"
                                        @click="onClickDescription(content.id)"
                                    />
                                </div>
                                <div class="right">
                                    <span class="c-red f-medium f-15" v-html="content.price" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="content-box" v-else>
                        <li class="contents f-15" v-for="content in item.contents" :key="content.id">
                            <span v-html="content.title" /> <br />
                            <span class="f-13 content-subtitle">{{
                                content.subtitle ? '(' + content.subtitle + ')' : null
                            }}</span>
                        </li>
                    </ul>
                    <div class="description m-t-11 f-11" v-html="item.description" />
                </div>
            </div>

            <ul class="question-box">
                <li
                    class="questions p-20 f-15"
                    v-for="item in contentQuestion"
                    :key="item.id"
                    @click="toggleDescription(item.id)"
                    :class="{ open: item.id === openedQuestionId }"
                >
                    <div class="questions-content">
                        <div class="question-left">
                            <span class="question-mark">Q.</span>
                            <span class="question-title m-l-8" v-html="item.title" />
                        </div>
                        <img
                            :src="
                                require(`@/assets/images/icon-${item.id === openedQuestionId ? 'open' : 'close'}.webp`)
                            "
                            alt=""
                            width="24px"
                        />
                    </div>
                    <p class="question-desc" v-if="item.id === openedQuestionId" v-html="item.description" />
                </li>
            </ul>
        </div>
        <!-- <BottomButton :needAddInfoTop="false" :disabled="disabled" @click="submit" :label="$translate('SAVE')" /> -->
    </div>
</template>

<script>
export default {
    name: 'FeeInformationPage',
    // components: { EditIntro },
    data: () => ({
        descriptionFirst: false,
        descriptionSecond: false,
        showHeaderTitle: true,
        openedQuestionId: null,
    }),
    created() {
        this.$registerBackHandler(this.back)
    },
    beforeDestroy() {
        this.$unregisterBackHandler()
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        firstSectionContent() {
            return '굿퍼슨은 진정성 있고 책임감있는 만남 문화를 만들어가기 위해 약속 관련한 페널티 정책은 엄격하게 시행하고 있습니다.'
        },
        contentTitles() {
            return [
                {
                    id: 1,
                    title: '약속 취소/ 미진행 수수료 완료 안내',
                    subtitle: null,
                    description:
                        '*취소 수수료를 내지 않기 위해) 상대방과 대화방이 열렸을 때 직접 취소하고 회사에 고지하지 않는 경우나, 약속 No show/잠수의 경우 15만원의 페널티 수수료가 부과됩니다.',
                    contents: [
                        {
                            id: 1,
                            title: '약속 미진행',
                            price: '2만원',
                            icon: require('@/assets/images/schedule_unprocessed.png'),
                        },
                        {
                            id: 2,
                            title: '약속 취소',
                            price: '5만원',
                            icon: require('@/assets/images/cancel.png'),
                        },
                    ],
                },
                {
                    id: 2,
                    title: '수수료 면제 사유',
                    subtitle: '약속 변경이 불가능한 경우',
                    description: '*고객센터에 증빙할 수 있는 자료 (진단서 등)을 제출해야 인정됩니다.',
                    contents: [
                        {
                            id: 1,
                            title: '심각한 질병 및 부상',
                            subtitle: '치료기간 2주 이상',
                        },
                        {
                            id: 2,
                            title: '교제를 시작한 이성이 생긴 경우',
                            subtitle: '썸의 경우는 인정하지 않음',
                        },
                        {
                            id: 3,
                            title: '부모님, 형제의 작고',
                            subtitle: null,
                        },
                    ],
                },
                {
                    id: 3,
                    title: '수수료 면제가 불가한 사유',
                    subtitle: '약속 변경이 가능한 경우',
                    description: null,
                    contents: [
                        {
                            id: 1,
                            title: '개인 사정/일정',
                            subtitle: '가족 모임, 지인 약속 등',
                        },
                        {
                            id: 2,
                            title: '회사 업무/일정',
                            subtitle:
                                '회사 업무가 바쁜거나 출장의 경우 일정 변경이 가능하기 때문에 면제 사유에 해당하지 않습니다.',
                        },
                        {
                            id: 3,
                            title: '질병/부상 (치료 기간 2주 이내)',
                            subtitle: null,
                        },
                        {
                            id: 4,
                            title: '단순 변심',
                            subtitle: null,
                        },
                    ],
                },
            ]
        },
        contentQuestion() {
            return [
                {
                    id: 1,
                    title: '왜 수수료를 부과하나요?',
                    description: `굿퍼슨은 진정성 있고 책임감 있는 만남을 지향합니다.<br /><br />약속 미진행의 경우에, 프로필을 수락할 때 '만남 의향이 있는 경우'에만 수락을 해주시라고 안내하고 있습니다. 따라서,
                         양쪽이 프로필을 서로 수락한 순간 이미 서로 만날 의사가 있다는 전제로 약속 잡기가 시작됩니다.<br /><br />이런 일들을 방지하고 매너있는 만남 문화를 위해 부득이하게 페널티 수수료 제도를 운영합니다.`,
                },
                {
                    id: 2,
                    title: ' 회사 업무 때문에 만나기 어려워요. 근데 왜 페널티가 부과되나요?',
                    description: `회사 업무가 바쁜 경우에 일정 변경이 가능하기 때문에, 취소를 요청한 경우 단순 변심(만날 의지가 없음)으로 간주하고 페널티를 부과합니다.
                        <br />(한 달 이상 타지역으로 장기간 출장하는 등 약속 변경이 정말 불가피한 경우에는 수수료를 면제해드립니다. 고객센터로 연락주세요.)`,
                },
                {
                    id: 3,
                    title: ' 아파서 만나기 어려워요. 근데 왜 페널티가 부과되나요?',
                    description: `일시적으로 몸이 아픈 경우에 일정 변경이 가능하기 때문에, 취소를 요청한 경우 단순 변심(만날 의지가 없음)으로 간주하고 페널티를 부과합니다. 
                        <br />(장기간 입원 및 수술을 하는 등 약속 변경이 정말 불가피한 경우에는 수수료를 면제해드립니다. 고센터로 연락주세요.)`,
                },
            ]
        },
    },
    methods: {
        back() {
            this.$stackRouter.pop()
        },
        onClickDescription(id) {
            if (id === 1) {
                this.descriptionFirst = !this.descriptionFirst
                this.descriptionSecond = false
            } else {
                this.descriptionSecond = !this.descriptionSecond
                this.descriptionFirst = false
            }
        },
        toggleDescription(id) {
            this.openedQuestionId = this.openedQuestionId === id ? null : id
        },
    },
}
</script>

<style lang="scss" scoped>
.fee-information {
    $header-height: 52px;
    overflow: hidden;
    line-height: 150%;

    .main {
        height: calc(100vh - #{$header-height} - 48px);
        overflow-y: auto;
    }

    .content-section {
        padding: 20px;
    }

    .first-section {
        border-radius: 8px;
        background: var(--Cool-Grey, #e8e4e7);
        color: #5c5c77;
    }

    .content-titles {
        position: relative;

        .content-box {
            border-radius: 8px;
            background: #fff;
            padding: 11px 2px;

            .contents:nth-child(1).contents-first {
                /* 첫 번째 자식에만 적용할 스타일 */
                padding-top: 4px;
                padding-bottom: 14px;
            }

            .contents:nth-child(2).contents-first {
                /* 두 번째 자식에만 적용할 스타일 */
                padding-top: 14px;
                padding-bottom: 4px;
            }

            .contents {
                align-items: center;
                &.contents-first {
                    margin: 0 20px;
                    .content-container {
                        display: flex;
                        justify-content: space-between;
                    }
                }

                .left {
                    display: flex;
                    align-items: center;
                }
            }

            li {
                margin-left: 30px;
                list-style-type: disc;
                padding-bottom: 11px;

                .content-subtitle {
                    color: #787878;
                }
            }
        }

        .description {
            color: #a9a9a9;
        }
    }

    .question-box {
        .questions {
            background-color: #faf0e1;
            margin-bottom: 3px;

            &.open {
                background-color: #fff9ef;
            }

            .questions-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .question-left {
                    display: flex;
                }
                .question-title {
                    width: 264px;
                    color: #3a3a3a;
                    font-size: 15px;
                    font-family: Pretendard;
                    font-weight: 500;
                    line-height: 21px;
                }
            }

            .question-desc {
                color: #3a3a3a;
                margin-top: 18px;
                font-family: Pretendard;
                font-size: 13px;
                line-height: 19.5px;
            }
        }
    }

    .cancel-description {
        position: absolute; /* 혹은 absolute 등을 사용하여 원하는 위치로 조절 */
        width: 80%;
        top: 0px;
        right: -12px;
        // z-index: 9999;

        @media screen and (max-width: 300px) {
            top: -1px;
            right: -12px;
        }

        @media screen and (min-width: 300px) and (max-width: 400px) {
            top: -20px;
            right: -19px;
        }

        @media screen and (min-width: 400px) and (max-width: 700px) {
            top: -26px;
            right: -12px;
        }

        @media screen and (min-width: 700px) {
            top: -110px;
            right: 110px;
        }

        @media screen and (min-width: 800px) {
            top: -90px;
            right: 75px;
        }
    }
    .cancel-description-2 {
        position: absolute; /* 혹은 absolute 등을 사용하여 원하는 위치로 조절 */
        width: 80%;
        top: 50px;
        right: -12px;
        // z-index: 9999;

        @media screen and (max-width: 300px) {
            width: 90%;
            top: 5px;
            right: -19px;
        }

        @media screen and (min-width: 300px) and (max-width: 400px) {
            top: 44px;
            right: -7px;
        }

        @media screen and (min-width: 400px) and (max-width: 700px) {
            top: 36px;
            right: 5px;
        }

        @media screen and (min-width: 700px) {
            top: -33px;
            right: 125px;
        }

        @media screen and (min-width: 800px) {
            top: -18px;
            right: 35px;
        }
    }
}
</style>
